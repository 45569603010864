import React from "react";
import MuiButton, { ButtonProps, buttonClasses } from "@mui/material/Button";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";
import { styled } from '@mui/material/styles';
import { ReplayRounded } from "@material-ui/icons";


const PrimaryButton = styled(MuiButton)(({ theme }) => ({
	[`&.${buttonClasses.root}`]: {
        fontFamily: ["Open Sans", "sans-serif", "Lato"].join(),
		boxShadow: "none",
		color: "#FFFFFF",
		textTransform: "none",
		//padding: theme.spacing(0.75, 2),
		backgroundColor: "#007BC7",
		borderRadius: theme.spacing(1),
		"&:disabled": {
			borderColor: "transparent",
			backgroundColor: "#007BC7",
			opacity: "20%",
			"& span": {
				color: "#FFFFFF",
				opacity: 1,
			},
		},
		"&:hover": {
			backgroundColor: "#00539B",
			borderColor: "#0062cc",
			boxShadow: "none",
		},
		"&:active": {
			boxShadow: "none",
			backgroundColor: "#0062cc",
			borderColor: "#005cbf",
		},
		"&:focus": {
			boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
		},
	},
}));

const WhiteButton = styled(MuiButton)(({ theme }) => ({
	[`&.${buttonClasses.root}`]: {
        fontFamily: ["Open Sans", "sans-serif", "Lato"].join(),
		boxShadow: "0px 2px 8px #00000033",
		color: theme.palette.primary.main,
		textTransform: "none",
		fontWeight: "bold",
		background: "white",
		borderRadius: theme.spacing(1),
		padding: "10px 20px",

		"&:disabled": {
			"& span": {
				color: "#FFFFFF",
				opacity: 1,
			},
		},
		"&:hover": {
			background: "white",
			borderColor: "lightgray",
			boxShadow: "none",
		},
		"&:active": {
			boxShadow: "none",
		},
		"&:focus": {
			boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
		},
	},
}));

const LightButton = styled(MuiButton)(({ theme }) => ({
	[`&.${buttonClasses.root}`]: {
        fontFamily: ["Open Sans", "sans-serif", "Lato"].join(),
		boxShadow: "none",
		background: "white",
		color: "#505050",
		textTransform: "none",
		//fontWeight: 700,
		padding: theme.spacing(1, 2),
		border: `1px solid ${theme.palette.divider}`,
		lineHeight: 1.5,
		borderRadius: theme.spacing(1),
		"&:disabled": {
			borderColor: "#C2C2C2",
			color: "#8C8C8C",
		},
		"&:hover": {
			backgroundColor: "#F6F6F8",
			borderColor: "#5c5c5c",
			boxShadow: "none",
		},
		"&:active": {
			boxShadow: "none",
			backgroundColor: "#F6F6F8",
			borderColor: "#808080",
		},
		"&:focus": {
			boxShadow: "0 0 0 0.2rem rgba(195, 195, 195, .3)",
		},
	},
}));

const TextSecondaryButton = styled(MuiButton)(({ theme }) => ({
	[`&.${buttonClasses.root}`]: {
        fontFamily: ["Open Sans", "sans-serif", "Lato"].join(),
		color: theme.palette.secondary.main,
		textTransform: "none",
		textDecoration: "underline",
		//padding: 8,
		"&:disabled": {
			color: theme.palette.grey[600],
			textDecoration: "underline",
		},
		"&:hover": {
			color: theme.palette.secondary.dark,
			textDecoration: "underline",
		},
	},
}));

const TextPrimaryButton = styled(MuiButton)({
	[`&.${buttonClasses.root}`]: {
        fontFamily: ["Open Sans", "sans-serif", "Lato"].join(),
		color: "#007BC7",
		textTransform: "none",
		textDecoration: "underline",
		//padding: 8,
		"&:disabled": {
			color: "#8C8C8C",
			textDecoration: "underline",
		},
		"&:hover": {
			color: "#00539B",
			textDecoration: "underline",
		},
	},
});

const DarkButton = styled(MuiButton)({
	[`&.${buttonClasses.root}`]: {
        fontFamily: ["Open Sans", "sans-serif", "Lato"].join(),
		boxShadow: "none",
		color: "#FFFFFF",
		textTransform: "none",
		padding: "6px 12px",
		border: "1px solid",
		backgroundColor: "#696969",
		borderColor: "#595959",
		margin: "20px 0",
		"&:disabled": {
			borderColor: "transparent",
			backgroundColor: "#007BC7",
			opacity: "20%",
			"& span": {
				color: "#FFFFFF",
				opacity: 1,
			},
		},
		"&:hover": {
			backgroundColor: "#595959",
			borderColor: "#505050",
			boxShadow: "none",
		},
		"&:active": {
			boxShadow: "none",
			backgroundColor: "#606060",
			borderColor: "#555555",
		},
		"&:focus": {
			boxShadow: "0 0 0 0.2rem rgba(50,50,50,.5)",
		},
	},
});

const ButtonProgress = styled(CircularProgress)({
	[`&.${circularProgressClasses.svg}`]: {
		fontSize: 10,
		color: "white",
	},
});

const RestoreButton = styled(MuiButton)(({ theme }) => ({
	[`&.${buttonClasses.root}`]: {
        fontFamily: ["Open Sans", "sans-serif", "Lato"].join(),
		boxShadow: "none",
		color: "#505050",
		textTransform: "none",
		//padding: theme.spacing(1, 2),
		fontWeight: "bold",
		borderRadius: theme.spacing(1),
		backgroundColor: "#F4F5F6",
		"&:disabled": {
			opacity: "20%",
		},
	}
}));


interface Props extends ButtonProps {
	mode?: string;
	loading?: boolean;
	children: any;
}

const Button: React.FC<Props> = ({ children, mode, loading, ...rest }) => {
	switch (mode) {
		default:
			return (
				<PrimaryButton {...rest}>
					{loading ? <ButtonProgress size={22} /> : children}
				</PrimaryButton>
			);

		case "light":
			return (
				<LightButton {...rest}>
					{loading ? <ButtonProgress size={22} /> : children}
				</LightButton>
			);

		case "text-primary":
			return (
				<TextPrimaryButton {...rest}>
					{loading ? <ButtonProgress size={22} /> : children}
				</TextPrimaryButton>
			);

		case "text-secondary":
			return (
				<TextSecondaryButton {...rest}>
					{loading ? <ButtonProgress size={22} /> : children}
				</TextSecondaryButton>
			);

		case "dark":
			return (
				<DarkButton {...rest}>
					{loading ? <ButtonProgress size={22} /> : children}
				</DarkButton>
			);

		case "white":
			return (
				<WhiteButton {...rest}>
					{loading ? <ButtonProgress size={22} /> : children}
				</WhiteButton>
			);

		case "restore":
			return (
				<RestoreButton startIcon={<ReplayRounded style={{ transform: "rotate(-45deg)"}} />} {...rest}>
					{loading ? <ButtonProgress size={22} /> : children}
				</RestoreButton>
			)
	}
};

export default Button;

/* export type ButtonProps = {
  text: string, 
  disabled ?: boolean,
  action(e: React.MouseEvent<HTMLButtonElement>): void
}
 
const BootstrapButton: FC<ButtonProps> = ({ text, action, disabled }) => {
  const classes = useStyles();

  return (
	<Button
	  classes ={{ root: classes.root }}
	  variant="contained" color="primary" disableRipple
	  onClick ={ action }
	  disabled={ disabled }
	>
	  { text }
	</Button>
  );
}
 
export default BootstrapButton; */
