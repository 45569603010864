import { Dispatch } from 'redux';
import { isString } from 'lodash';
import { FacebookService } from '../../../services/FacebookService';
import { IFacebookTouchpoint, TouchpointFormError } from '../../../models';
import { processTouchpoint } from '../../../utils';
import { getCampaign } from '../../Campaigns';
import { history } from '../../../router';
import * as types from './types';

export const setTouchpoints = (touchpoints: any[]) => {
	return {
		type: types.SET_FB_TOUCHPOINTS,
		payload: touchpoints
	}
}

export const setTouchpoint = (touchpoint: any) => {
	return {
		type: types.SET_FB_TOUCHPOINT,
		payload: touchpoint
	}
}

export const setTouchpointStep = (step: number) => {
	return {
		type: types.SET_FB_TOUCHPOINT_STEP,
		payload: `panel${step}`
	}
}

export const setPrices = (prices: any) => {
	return {
		type: types.SET_FB_TOUCHPOINT_PRICES,
		payload: prices
	}
}

export const getTouchpoint = (credentials: any) => async (dispatch: Dispatch<types.DispatchTypes>) => {
	try {
		const { accountId, touchpointId } = credentials;
		dispatch({ type: types.GET_FB_TOUCHPOINT_REQUEST });

		const service = new FacebookService(accountId);
		const touchpoint = await service.getFbTouchpoint(touchpointId);

		processTouchpoint(touchpoint);

		dispatch({
			type: types.GET_FB_TOUCHPOINT_SUCCESS,
			payload: touchpoint,
		});

	} catch (error) {
		dispatch({
			type: types.GET_FB_TOUCHPOINT_FAILED,
			payload: (error as any).message
		})
	}
}

export const updateTouchpoint = (touchpoint: any, accountId: string, step?: number) => async (dispatch: Dispatch<types.DispatchTypes>) => {
	try {
		dispatch({ type: types.UPDATE_FB_TOUCHPOINT_REQUEST });

		const service = new FacebookService(accountId);
		const res = await service.updateFbTouchpoint(touchpoint);

		if (res === 200) {
			dispatch({
				type: types.UPDATE_FB_TOUCHPOINT_SUCCESS,
				payload: res
			});

			const newTouchpoint = await service.getFbTouchpoint(touchpoint.id);

			if (newTouchpoint) {
				dispatch({
					type: types.SET_FB_TOUCHPOINT,
					payload: newTouchpoint
				});
			}

			if (step) {
				dispatch({
					type: types.SET_FB_TOUCHPOINT_STEP,
					payload: `panel${step}`
				})
			}
		} else {
			throw res.errors;
		}

	} catch (error) {
		if ((error as any)?.errors) {
			const { errors } = error as any;
			console.log(Object.keys(errors));
		}

		return dispatch({
			type: types.UPDATE_FB_TOUCHPOINT_FAILED,
			payload: (error as any).message,
		});
	}
}

export const updateTouchpointRecommendedAudiences = (touchpoint: any, audienceIds: string[], callback: () => void) => async (dispatch: Dispatch<types.DispatchTypes>) => {
	try {
		dispatch({ type: types.UPDATE_FB_TOUCHPOINT_REQUEST });

		const service = new FacebookService(touchpoint.accountId);
		const res = await service.updateRecommendedAudiences(touchpoint.id, audienceIds);

		if (res.status === 200) {
			dispatch({
				type: types.UPDATE_FB_TOUCHPOINT_SUCCESS,
				payload: res
			});

			const newTouchpoint = await service.getFbTouchpoint(touchpoint.id);

			if (newTouchpoint) {
				dispatch({
					type: types.SET_FB_TOUCHPOINT,
					payload: newTouchpoint
				});
			}

		}

		callback();

	} catch (error) {
		if ((error as any)?.errors) {
			const { errors } = error as any;
			console.log(Object.keys(errors));
		}

		return dispatch({
			type: types.UPDATE_FB_TOUCHPOINT_FAILED,
			payload: (error as any).message,
		});
	} 
}

export const restoreTouchpointValues = ( touchpointId: string, field: number) => async (dispatch: Dispatch<types.DispatchTypes>) => {
	try {
		const service = new FacebookService("");

		const response = await service.restoreTouchpointValues(touchpointId, field);

		dispatch({
			type: types.SET_FB_TOUCHPOINT,
			payload: response
		})

	} catch (error) {
		dispatch({
			type: types.UPDATE_FB_TOUCHPOINT_FAILED,
			payload: (error as any).message,
		});
	}
}

export const saveTouchpoint = (touchpoint: IFacebookTouchpoint, accountId: string) => async (dispatch: Dispatch<types.DispatchTypes>) => {
	try {
		dispatch({ type: types.UPDATE_FB_TOUCHPOINT_REQUEST });

		const { id } = touchpoint;
		const service = new FacebookService(accountId);
		const formErrors: Array<TouchpointFormError> = [];
		//validate touchpoint completion
		if (!touchpoint.pageId && touchpoint.touchpointType === 0) formErrors.push({ id, field: 'pageId', desc: 'A Facebook Page is required.', step: 2 });
		if (!touchpoint.primarytext) formErrors.push({ id, field: 'primarytext', desc: 'Primary Text is required.', step: 3 });
		if (!touchpoint.description && touchpoint.touchpointType === 0) formErrors.push({ id, field: 'description', desc: 'Description is required.', step: 3 });
		if (!touchpoint.link) formErrors.push({ id, field: 'link', desc: 'Field is required.', step: 3 });
		if (!touchpoint.headLine) formErrors.push({ id, field: 'headLine', desc: 'Headline is required.', step: 3 });
		if (!touchpoint.image) formErrors.push({ id, field: 'image', desc: 'Image is required.', step: 3 });
		if (touchpoint.startDate === null) formErrors.push({ id, field: 'startDate', desc: 'Field is required.', step: 4 });
		if (!touchpoint.endDate === null) formErrors.push({ id, field: 'endDate', desc: 'Field is required.', step: 4 });

		//update touchpoint
		const update = await service.updateFbTouchpoint(touchpoint);

		dispatch({
			type: types.UPDATE_FB_TOUCHPOINT_SUCCESS,
			payload: update
		});

		if (formErrors.length > 0) {
			//set errors if any and return,
			dispatch({
				type: types.SET_FB_FORM_ERRORS,
				payload: formErrors,
			});

			dispatch({
				type: types.SET_FB_TOUCHPOINT_STEP,
				payload: `panel${formErrors[0].step}`
			});

			return;
		}

		history.push("/app/shopping-cart?target=" + touchpoint.campaignId);
		//redirect if no error
	} catch (error) {
		dispatch({
			type: types.UPDATE_FB_TOUCHPOINT_FAILED,
			payload: (error as any).message,
		});
	}
}

export const clearTouchpointFormErrors = () => ({ type: types.RESET_FB_FORM_ERRORS });

export const deleteTouchpoint = (credentials: any) => async (dispatch: Dispatch<types.DispatchTypes>) => {
	try {
		dispatch({ type: types.DELETE_FB_TOUCHPOINT_REQUEST });
		const { campaignId, accountId, touchpointId } = credentials;

		const service = new FacebookService(accountId);
		const res = await service.deleteFbTouchpoint(touchpointId);

		if (res) {
			dispatch({
				type: types.DELETE_FB_TOUCHPOINT_SUCCESS,
				payload: touchpointId,
			});

			getCampaign(accountId, campaignId);
		}

	} catch (error) {
		dispatch({
			type: types.DELETE_FB_TOUCHPOINT_FAILED,
			payload: (error as any).message
		})
	}
}

export const getTouchpointAudiences = (credentials: any) => async (dispatch: Dispatch<types.DispatchTypes>) => {
	try {
		const { accountId, touchpointId } = credentials;
		dispatch({ type: types.GET_FB_TOUCHPOINT_AUDIENCES_REQUEST });

		const service = new FacebookService(accountId)
		const audiences = await service.getFbTouchpointAudiences(touchpointId);

		dispatch({
			type: types.GET_FB_TOUCHPOINT_AUDIENCES_SUCCESS,
			payload: audiences,
		});

	} catch (error) {
		dispatch({
			type: types.GET_FB_TOUCHPOINT_AUDIENCES_FAILED,
			payload: (error as any).message
		})
	}
}

export const uploadImage = (form: any) => async (dispatch: Dispatch<types.DispatchTypes>) => {
	try {
		dispatch({ type: types.UPLOAD_FB_IMAGE_REQUEST });


		const { accountId, touchpointId, file } = form;
		const service = new FacebookService(accountId);
		const res = await service.uploadFbTouchpointImage(touchpointId, file);
		const timestamp = '?t=' + new Date().getTime();
		console.log("File before send :", file)
		if (res) {
			if (isString(res)) {
				dispatch({
					type: types.UPLOAD_FB_IMAGE_SUCCESS,
					payload: {
						image: res + timestamp,
						id: touchpointId,
					}
				});
			} else {
				dispatch({
					type: types.UPLOAD_FB_IMAGE_FAILED,
					payload: res.message
				});
			}
		}

	} catch (error) {
		dispatch({
			type: types.UPLOAD_FB_IMAGE_FAILED,
			payload: (error as any).message
		})
	}
}

export const removeImage = (accountId: string, touchpointId: string) => async (dispatch: Dispatch<types.DispatchTypes>) => {
	try {
		dispatch({
			type: types.REMOVE_FB_IMAGE_REQUEST
		});

		const service = new FacebookService(accountId);
		await service.removeFbTouchpointImage(touchpointId);

		dispatch({
			type: types.REMOVE_FB_IMAGE_SUCCESS,
			payload: touchpointId,
		});

	} catch (error) {
		dispatch({
			type: types.REMOVE_FB_IMAGE_FAILED,
			payload: (error as any).message
		});
	}
}