import { Dispatch } from "redux";
import { EmailBlastService } from "../../../services/EmailBlastService";
import { RecommendedAudienceService } from "../../../services/RecommendedAudienceService";
import { IBaseImageContent, IEmailBlastTemplate, IEmailBlastTouchpoint, IEmailTemplateContent, ITemplateBookItem } from "../../../models";
import { processTouchpoint } from '../../../utils/Touchpoint';
import { history } from '../../../router';
import { store } from '../../../store';
import { getCampaign } from '../../Campaigns';
import * as types from './types';

export const setTouchpoints = (touchpoints: IEmailBlastTouchpoint[]) => ({
    type: types.SET_EBLAST_TOUCHPOINTS,
    payload: touchpoints
});

export const setTouchpoint = (touchpoint: IEmailBlastTouchpoint) => {
    const emailTemplate = processTemplateContent(touchpoint);

    if (emailTemplate) {
        touchpoint.emailTemplate = emailTemplate
    }

    return {
        type: types.SET_EBLAST_TOUCHPOINT,
        payload: touchpoint
    }
};

export const setTouchpointStep = (step: number) => ({
    type: types.SET_EBLAST_TOUCHPOINT_STEP,
    payload: `panel${step}`
});

export const initializeTouchpointTemplate = (touchpoint: IEmailBlastTouchpoint, books: ITemplateBookItem[], callback?: ()=>void) => 
    async (dispatch: Dispatch<types.DispatchTypes>) => {
        try {
            dispatch({ type: types.INITIALIZE_TOUCHPOINT_TEMPLATE_REQUEST});
            const SINGLE_TITLE_TEMPLATE_ID  = process.env.REACT_APP_EBLAST_SINGLE_TITLE_TEMPLATE_ID as string;
            const MULTI_TITLE_TEMPLATE_ID  = process.env.REACT_APP_EBLAST_MULTI_TITLE_TEMPLATE_ID as string;
            if (!SINGLE_TITLE_TEMPLATE_ID || !MULTI_TITLE_TEMPLATE_ID) throw new Error("Missing template local ids")
            
            const emailTemplateContent: IEmailTemplateContent = { 
                header: null,
                footer: null,
                books,
                editorialHeadline: "",
                editorialCopy: "",
                publisherName: "",
            };

            if (books.length > 1) {
                touchpoint.emailTemplateId = MULTI_TITLE_TEMPLATE_ID;
            } else {
                touchpoint.emailTemplateId = SINGLE_TITLE_TEMPLATE_ID;
            }

            touchpoint.emailTemplateContent = emailTemplateContent;
            
            const emailTemplate = processTemplateContent(touchpoint);
            if (!emailTemplate) throw new Error("Content couldn't be prepared");

            touchpoint.emailTemplate = emailTemplate;

            const service = new EmailBlastService(touchpoint.id);
            await service.updateTouchpoint(touchpoint);
            const updated = await service.getTouchpoint();
            
            dispatch({
                type: types.SET_EBLAST_TOUCHPOINT,
                payload: updated as IEmailBlastTouchpoint
            });
            
        } catch (error) {
            dispatch({
                type: types.INITIALIZE_TOUCHPOINT_TEMPLATE_FAILED,
                payload: (error as any).message
            });
        } finally {
            dispatch({ type: types.INITIALIZE_TOUCHPOINT_TEMPLATE_SUCCESS });
            if(callback)
                callback();
        }
    }

export const getTouchpoint = (touchpointId: string) => async (dispatch: Dispatch<types.DispatchTypes | any>) => {
    try {
        dispatch({ type: types.GET_EBLAST_TOUCHPOINT_REQUEST });

        const service = new EmailBlastService(touchpointId);
        const touchpoint = await service.getTouchpoint();
        processTouchpoint(touchpoint);

        dispatch({ 
            type: types.GET_EBLAST_TOUCHPOINT_SUCCESS, 
            payload: touchpoint as IEmailBlastTouchpoint
        });

    } catch (error) {
        dispatch({
            type: types.GET_EBLAST_TOUCHPOINT_FAILED,
            payload: error as any
        });
    } finally {
        if (
            store.getState().emailBlastTouchpoints.templates.data.length === 0  //no templates loaded
            && !store.getState().emailBlastTouchpoints.loading                  //no templates loading
        ) {
            dispatch(getTemplates(touchpointId));
        }
    }
}

export const updateTouchpoint = (touchpoint: IEmailBlastTouchpoint, accountId: string, callback?: () => void) => async (dispatch: Dispatch<types.DispatchTypes>) => {
    try {
        dispatch({ type: types.UPDATE_EBLAST_TOUCHPOINT_REQUEST });

        const service = new EmailBlastService(touchpoint.id);
        await service.updateTouchpoint(touchpoint);
        const updated = await service.getTouchpoint();
        processTouchpoint(updated);

        dispatch({
            type: types.SET_EBLAST_TOUCHPOINT,
            payload: updated as IEmailBlastTouchpoint
        });

        dispatch({ 
            type: types.UPDATE_EBLAST_TOUCHPOINT_SUCCESS, 
        });

        if (callback) {
            callback();
        }

    } catch (error) {
        dispatch({
            type: types.UPDATE_EBLAST_TOUCHPOINT_FAILED,
            payload: error as any
        });
    }
}

export const saveTouchpoint = (touchpoint: IEmailBlastTouchpoint, accountId: string) => async (dispatch: Dispatch<types.DispatchTypes | any>) => {
    try {
        
        dispatch(updateTouchpoint(touchpoint, accountId));

        history.push("/app/shopping-cart?target="+ touchpoint.campaignId);

    } catch (error) {
        console.log(error);
    }
}

export const deleteTouchpoint = (credentials: any) => async (dispatch: Dispatch<types.DispatchTypes & any>) => {
    try {
      dispatch({type: types.DELETE_EBLAST_TOUCHPOINT_REQUEST});
      const { campaignId, accountId, touchpointId } = credentials;
  
      const service = new EmailBlastService(touchpointId);
      const res = await service.deleteTouchpoint();
  
      if(res) {
        dispatch({
          type: types.DELETE_EBLAST_TOUCHPOINT_SUCCESS,
          payload: touchpointId,
        });
        
        dispatch(getCampaign(accountId, campaignId));
      }
  
    } catch (error) {
      dispatch({
        type: types.DELETE_EBLAST_TOUCHPOINT_FAILED,
        payload: error
      })
    }
}

export const getAvailableDates = (audienceId: string) => async (dispatch: Dispatch<types.DispatchTypes>) => {
    try{
        dispatch({ type: types.GET_EBLAST_AVAILABLE_DATES_REQUEST });
        console.log('it came',audienceId)
        const service = new RecommendedAudienceService();
        const data = await service.getAudienceAvailableDates(audienceId);

        dispatch({
            type: types.GET_EBLAST_AVAILABLE_DATES_SUCCESS,
            payload: data
        });

    } catch (error) {
        console.log(error);
        dispatch({
            type: types.GET_EBLAST_AVAILABLE_DATES_FAILED,
            payload: (error as any).message,
        });
    }
}

export const uploadTemplateFooterImage = (touchpoint: IEmailBlastTouchpoint, image: any) => 
    async (dispatch: Dispatch<types.DispatchTypes>) => {
        try {
            const service = new EmailBlastService(touchpoint.id);
            const imageUrl = await service.uploadTemplateFooterImage(image);
            const timestamp = '?t=' + new Date().getTime();
            let footer: IBaseImageContent = { imageUrl: imageUrl + timestamp, imageLink: '' };

            if (!touchpoint.emailTemplateContent.footer) {
                touchpoint.emailTemplateContent.footer = footer;
            } else {
                touchpoint.emailTemplateContent.footer.imageUrl = imageUrl + timestamp;
            }
            
            const emailTemplate = processTemplateContent(touchpoint);

            if (emailTemplate) {
                touchpoint.emailTemplate = emailTemplate
            }

            dispatch({
                type: types.SET_EBLAST_TOUCHPOINT,
                payload: touchpoint
            })

        } catch (error) {
            console.log(error);
        }
    }

export const uploadTemplateBookCoverImage = (touchpoint: IEmailBlastTouchpoint, image: any, isbn: string) => 
    async (dispatch: Dispatch<types.DispatchTypes>) => {
        try {
            console.log(image);
            const service = new EmailBlastService(touchpoint.id);
            const imageUrl = await service.uploadTemplateBookItemImage(image, isbn);
            const timestamp = '?t=' + new Date().getTime();

            const { books } = touchpoint.emailTemplateContent;
            const index = books.findIndex(book => book.isbn === isbn);
            books[index].coverImage = imageUrl + timestamp;

            const emailTemplate = processTemplateContent(touchpoint);

            if (emailTemplate) {
                touchpoint.emailTemplate = emailTemplate
            }

            dispatch({
                type: types.SET_EBLAST_TOUCHPOINT,
                payload: touchpoint
            })


        } catch (error) {
            console.log(error);
        }
    }

export const deleteTemplateBookCover = (touchpoint: IEmailBlastTouchpoint, isbn: string) => 
    (dispatch: Dispatch<types.DispatchTypes>) => {
        try {
            const { books } = touchpoint.emailTemplateContent;
            const index = books.findIndex(book => book.isbn === isbn);
            
            books[index].coverImage = "";
            const emailTemplate = processTemplateContent(touchpoint);
            if (emailTemplate) {
                touchpoint.emailTemplate = emailTemplate
            }

            dispatch({
                type: types.SET_EBLAST_TOUCHPOINT,
                payload: touchpoint
            })
            
        } catch (error) {
            console.log(error); 
        }
    }

export const uploadTemplateHeaderImage = (touchpoint: IEmailBlastTouchpoint, image: any) => 
    async (dispatch: Dispatch<types.DispatchTypes>) => {
        try {

            const service = new EmailBlastService(touchpoint.id);
            const imageUrl = await service.uploadTemplateHeaderImage(image);
            const timestamp = '?t=' + new Date().getTime();

            let header: IBaseImageContent = { imageUrl: imageUrl + timestamp, imageLink: '' };

            if (!touchpoint.emailTemplateContent.header) {
                touchpoint.emailTemplateContent.header = header;
            } else {
                touchpoint.emailTemplateContent.header.imageUrl = imageUrl + timestamp
            }

            const emailTemplate = processTemplateContent(touchpoint);

            if (emailTemplate) {
                touchpoint.emailTemplate = emailTemplate
            }

            dispatch({
                type: types.SET_EBLAST_TOUCHPOINT,
                payload: touchpoint
            })

        } catch (error) {
            console.log(error);
        }
    }

export const getTemplates = (touchpointId: string) => async (dispatch: Dispatch<types.DispatchTypes>) => {
    try{
        dispatch({ type: types.GET_EBLAST_TEMPLATES_REQUEST });

        const service = new EmailBlastService(touchpointId);
        const data = await service.getTemplates();

        dispatch({
            type: types.GET_EBLAST_TEMPLATES_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: types.GET_EBLAST_TEMPLATES_FAILED,
            payload: error,
        });
    }
}

const getBrand = (touchpoint: IEmailBlastTouchpoint): string => {
        let brand: string = "Bookfinity";
        if (touchpoint.selectedProducts.length === 0) {
            return brand;
        } else {
            
            const product = touchpoint.products.find(p => p.id === touchpoint.selectedProducts[0]);
            
            if (product) {
                brand = product.recommendedAudience.emailBlastAudienceId;
            }
        }
        
        return brand;  
    }
    

export const processTemplateContent = (touchpoint: IEmailBlastTouchpoint) => {
    if (!touchpoint.emailTemplateContent || !touchpoint.emailTemplateId) return;

    const { emailTemplateContent } = touchpoint;


    const brand = getBrand(touchpoint);
    const templates: IEmailBlastTemplate[] = store.getState().emailBlastTouchpoints.templates.data;
    const template: IEmailBlastTemplate | undefined = templates.find((t: IEmailBlastTemplate) => t.id === touchpoint.emailTemplateId);
    if (!template) return; //throw new Error('If theres no template this cant be procesed. Make sure to get the templates from the portal backend and feed them to the store.');

    const parser = new DOMParser();

    let htmlFormat = "";

    if (brand === "Little Infinite") {
        htmlFormat = template.htmlFormatLi
    } else {
        htmlFormat = template.htmlFormat
    } 

    const document = parser.parseFromString(htmlFormat, "text/html");
    //const templateLogoImage = document.querySelector('#brand-logo');
    const templateHeaderImage = document.querySelector("#header-image-img");
    const templateHeaderLink = document.querySelector("#header-image-link");
    const templateFooter = document.querySelector('#footer-banner-image');
    const templateBooks = document.querySelectorAll("#book");
    const templateDivder = document.querySelector("#divider-line")
    const templateHeadline = document.querySelector("#body-title");
    const templateCopy = document.querySelector("#body-text");
    const templatePublisherName = document.querySelector("#sponsoredrights-line");

    if (templatePublisherName && emailTemplateContent.publisherName) {
        templatePublisherName.innerHTML = templatePublisherName.innerHTML.replace("{ Name of Publisher }", emailTemplateContent.publisherName);
    }

/*     if (templateLogoImage && brand && brand !== "Bookfinity") {

        if (brand === "LiRomance") {
            templateLogoImage.setAttribute("src", "https://47118194.fs1.hubspotusercontent-na1.net/hubfs/47118194/little%20infinite%20Black%20Poetry%20for%20Life%20Logo%201999x441.png");
        }

        if (brand === "LittleInfinite") {
            templateLogoImage.setAttribute("src", "https://47118194.fs1.hubspotusercontent-na1.net/hubfs/47118194/little%20infinite%20Black%20Poetry%20for%20Life%20Logo%201999x441.png");
        }
        /*         
        if (brand === "PagePairing") {
            templateLogoImage.setAttribute("src", "https://image.email.bookfinity.com/lib/fe39157075640574761577/m/1/9a57366d-1cfc-4792-a816-b3311c71a0d4.png");
        }
    } */
    
    if (emailTemplateContent.header) {
        
        templateHeaderImage?.setAttribute("src", emailTemplateContent.header.imageUrl);
        templateHeaderLink?.setAttribute("href", emailTemplateContent.header.imageLink);
 
        if (!emailTemplateContent.header.imageUrl) {
            templateHeaderLink?.remove();
        }

    } else {
        templateHeaderLink?.remove()
    }

    if (emailTemplateContent.footer) {
        
        if (templateFooter) {
            const image = templateFooter.querySelector("img");
            const link = templateFooter.querySelector("a");
            image?.setAttribute("src", emailTemplateContent.footer.imageUrl);
            link?.setAttribute("href", emailTemplateContent.footer.imageLink);
        }
        
        if (!emailTemplateContent.footer.imageUrl) {
            templateFooter?.remove()
        }

    } else {
        templateFooter?.remove()
    }

    if (!emailTemplateContent.editorialHeadline && !emailTemplateContent.editorialCopy) {
        templateDivder?.remove();
    }

    if (emailTemplateContent.editorialHeadline && templateHeadline) {
        templateHeadline.innerHTML = emailTemplateContent.editorialHeadline;
    } else {
        templateHeadline?.remove()
    }

    if (emailTemplateContent.editorialCopy && templateCopy) {
        templateCopy.innerHTML = emailTemplateContent.editorialCopy
    } else {
        templateCopy?.remove()
    }

    templateBooks.forEach((book, index) => {
        const replacement = emailTemplateContent.books[index];
  
        if (replacement) {

          const coverElement = book.querySelector("#book-image");
          const titleElement = book.querySelector("#book-title");
          const authorElement = book.querySelector("#book-author");
          const descElement = book.querySelector("#book-description");
          const ctaElement = book.querySelector("#book-cta");
  
          if (coverElement) {
            const image = coverElement.querySelector("img");
            const imageLink = coverElement.querySelector("a");
  
            if (imageLink) {
              imageLink.setAttribute("href", replacement.link)
            }
  
            if (image) {
              image.setAttribute("src", replacement.coverImage);
              image.setAttribute("alt", replacement.title);
            }
          }
  
          if (titleElement) {
            titleElement.innerHTML = `<b>${replacement.title}</b>`;
          }
  
          if (authorElement) {
            authorElement.innerHTML = replacement.author ? ` By ${replacement.author}` : "";
          }
  
          if (descElement) {
            descElement.innerHTML = replacement.description;
          }
  
          if (ctaElement) {
            ctaElement.querySelector("a")?.setAttribute("href", replacement.link);
            const buttonText = ctaElement.querySelector("b");
            if (buttonText) {
              buttonText.innerHTML = brand === "LiRomance" 
                ? replacement.cta.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()) 
                : replacement.cta;
            }
          }
  
  
        } else {
          book.remove();
        }
    }); 

    let processedHTML = document.documentElement.innerHTML;
  
    // *** Post-processing of conditional comments for cross-platform elements ***
    emailTemplateContent.books.forEach((book) => {
        const { link } = book;
        
        const bookLinkPlaceholderRegex = /{{BookLink}}/i;
        processedHTML = processedHTML.replace(bookLinkPlaceholderRegex, link);

    });
    
    if (emailTemplateContent.header) {
        const { imageUrl } = emailTemplateContent.header;
    
        const headerImagePlaceholderRegex = /{{HeaderImage}}/g;
        processedHTML = processedHTML.replace(headerImagePlaceholderRegex, imageUrl);

    }

    if (emailTemplateContent.footer) {
        const { imageUrl } = emailTemplateContent.footer;
    
        const headerImagePlaceholderRegex = /{{FooterImage}}/g;
        processedHTML = processedHTML.replace(headerImagePlaceholderRegex, imageUrl);

    }

    return processedHTML;

}

