import * as types from "./types";

const initialState: types.EmailBlastTouchpointState = {
    touchpoints: [],
    dates: {
        loading: false,
        data: [],
        error: undefined
    },
    templates: {
        loading: false,
        data: [],
        error: undefined,
    },
    step: "panel1",
    loading: false,
    error: undefined,
    formErrors: [],
}

const emailBlastTouchpointReducer = (
    state: types.EmailBlastTouchpointState = initialState,
    action: any
) : types.EmailBlastTouchpointState => {

    switch(action.type) {

        case types.SET_EBLAST_TOUCHPOINT_STEP: {
            return {
              ...state,
              step: action.payload
            }
          }
      
          case types.SET_EBLAST_TOUCHPOINTS: {
            return {
              ...state,
              touchpoints: action.payload
            }
          }
      
          case types.SET_EBLAST_TOUCHPOINT: {
            const filtered = state.touchpoints.filter(
              (t) => t.id !== action.payload.id
            );
      
            return {
              ...state,
              touchpoints: [...filtered, action.payload]
            }
          }

        case types.SET_EBLAST_FORM_ERRORS: {
            return {
                ...state,
                formErrors: action.payload
            }
        }

        case types.DELETE_EBLAST_FORM_ERROR: {
            return {
                ...state,
                formErrors: state.formErrors.filter(
                    (e) => e.field !== action.payload
                ),
            }
        }

        case types.GET_EBLAST_TOUCHPOINT_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }

        case types.GET_EBLAST_TOUCHPOINT_SUCCESS: {
            const filtered = state.touchpoints.filter(
                (t) => t.id !== action.payload.id
            );

            return {
                ...state,
                touchpoints: [...filtered, action.payload],
                loading: false
            }
        }

        case types.GET_EBLAST_TOUCHPOINT_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        }

        case types.UPDATE_EBLAST_TOUCHPOINT_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }

        case types.UPDATE_EBLAST_TOUCHPOINT_SUCCESS: {
            return {
                ...state,
                loading: false,
            }
        }

        case types.UPDATE_EBLAST_TOUCHPOINT_FAILED: {
            return {
               ...state,
               loading: false,
               error: action.payload 
            }
        }

        case types.INITIALIZE_TOUCHPOINT_TEMPLATE_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }

        case types.INITIALIZE_TOUCHPOINT_TEMPLATE_SUCCESS: {
            return {
                ...state,
                loading: false,
            }
        }

        case types.INITIALIZE_TOUCHPOINT_TEMPLATE_FAILED: {
            return {
               ...state,
               loading: false,
               error: action.payload 
            }
        }

        case types.DELETE_EBLAST_TOUCHPOINT_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }

        case types.DELETE_EBLAST_TOUCHPOINT_SUCCESS: {
            return {
                ...state,
                touchpoints: state.touchpoints.filter((t) => t.id !== action.payload),
                loading: false,
            }
        }

        case types.DELETE_EBLAST_TOUCHPOINT_FAILED: {
            return {
               ...state,
               loading: false,
               error: action.payload 
            }
        }

        case types.GET_EBLAST_AVAILABLE_DATES_REQUEST: {
            return {
                ...state,
                dates: {
                    ...state.dates,
                    loading: true,
                    data: initialState.dates.data,
                    error: initialState.dates.error,
                }
            }
        }

        case types.GET_EBLAST_AVAILABLE_DATES_SUCCESS: {
            return {
                ...state,
                dates: {
                    ...state.dates,
                    loading: false,
                    data: action.payload,
                }
            }
        }

        case types.GET_EBLAST_AVAILABLE_DATES_FAILED: {
            return {
                ...state,
                dates: {
                    ...state.dates,
                    loading: false,
                    error: action.payload,
                }
            }
        }

        case types.GET_EBLAST_TEMPLATES_REQUEST: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    loading: true,
                }
            }
        }

        case types.GET_EBLAST_TEMPLATES_SUCCESS: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    data: action.payload,
                    loading: false,
                }
            }
        }

        case types.GET_EBLAST_TEMPLATES_FAILED: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    loading: false,
                    error: action.payload
                }
            }
        }

        default: {
            return state;
        }
    }
}

export default emailBlastTouchpointReducer;