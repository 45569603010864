export class FacebookService {
	private readonly accountId: string
	private readonly baseUrl: string

	constructor(accountId: string) {
		this.accountId = accountId;
		this.baseUrl = `${process.env.REACT_APP_API_URL}`;

		return this;
	}

	postAccessToken(fbAccessToken: string, userName: string): Promise<any> {
		return fetch(`${this.baseUrl}/facebook/integrate/${this.accountId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; ',
			},
			credentials: 'include',
			body: JSON.stringify({ fbAccessToken, userName }),
		})
			.then(res => {
				if (res.ok) return res.json()
				else return Promise.reject(res.status)
			})
			.catch(error => Promise.reject(error))
	}

	validateIntegration(fbAccessToken: string, userName: string): Promise<any> {
		return fetch(
			`${this.baseUrl}/facebook/ValidateIntegration/${this.accountId}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json; ',
				},
				credentials: 'include',
				body: JSON.stringify({ fbAccessToken, userName }),
			}
		)
			.then(res => {
				if (res.ok) return res.json()
				else return res.json()
			})
			.then(json => {
				console.log(json)
				if (json.message) {
					throw json.message;
				}
				return json
			})
			.catch(error => Promise.reject(error))
	}

	getFbTouchpoint(touchpointId: string): Promise<any> {
		return fetch(`${this.baseUrl}/FacebookTouchpoint/${touchpointId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json; ',
			},
			credentials: 'include'
		})
			.then(res => {
				if (res.ok) return res.json()
			})
			.catch(error => Promise.reject(error))
	}

	getFbTouchpointAudiences(touchpointId: string): Promise<any> {
		return fetch(
			`${this.baseUrl}/FacebookTouchpoint/GetAudienceOptions/${touchpointId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json; ',
				},
				credentials: 'include'
			}
		)
			.then(res => {
				if (res.ok) return res.json()
			})
			.catch(error => Promise.reject(error))
	}

	deleteFbTouchpoint(touchpointId: string): Promise<any> {
		return fetch(`${this.baseUrl}/FacebookTouchpoint/${touchpointId}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json; ',
			},
			credentials: 'include'
		})
			.then(res => {
				if (res.ok) return res.status
			})
			.catch(error => Promise.reject(error))
	}

	updateFbTouchpoint(form: any): Promise<any> {
		const { id } = form
		return fetch(`${this.baseUrl}/FacebookTouchpoint/${id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json; ',
			},
			credentials: 'include',
			body: JSON.stringify(form),
		})
			.then(res => {
				if (res.ok) return res.status;
				else return res.json();
			})
			.catch(error => Promise.reject(error))
	}

	updateTouchpoint(form: any): Promise<any> {
		const { id } = form
		return fetch(`${this.baseUrl}/FacebookTouchpoint/${id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json; ',
			},
			credentials: 'include',
			body: JSON.stringify(form),
		})
			.then(res => res.ok ? res.status : res.json())
			.then(resAsJson => {
				if (resAsJson.message) {
					return Promise.reject(resAsJson);
				}

				return resAsJson;
			})
			.catch(error => Promise.reject(error))
	}

	updateRecommendedAudiences(touchpointId: string, audiendeIds: string[]) {
		return fetch(`${this.baseUrl}/FacebookTouchpoint/UpdateRecommendedAudiences/${touchpointId}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json; ',
			},
			credentials: 'include',
			body: JSON.stringify(audiendeIds),
		})
	}

	uploadFbTouchpointImage(touchpointId: string, file: any): Promise<any> {
		const files = new FormData();
		files.append('files', file);
		return fetch(
			`${this.baseUrl}/FacebookTouchpoint/UploadImage/${touchpointId}`,
			{
				method: 'POST',
				headers: {},
				credentials: 'include',
				body: files,
			}
		)
			.then(res => {
				if (res.ok) return res.text()
				else if (res.status === 400) return res.json()
			})
			.catch(error => Promise.reject(error))
	}

	removeFbTouchpointImage(touchpointId: string): Promise<any> {
		return fetch(`${this.baseUrl}/FacebookTouchpoint/RemoveImage/${touchpointId}`, {
			method: 'PUT',
			headers: {},
			credentials: 'include'
		})
			.catch(error => Promise.reject(error));
	}

	activateFbTouchpoint(touchpointId: string): Promise<any> {
		return fetch(
			`${this.baseUrl}/FacebookTouchpoint/Activate/${touchpointId}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json; ',
				},
				credentials: 'include'
			}
		)
			.then(res => {
				if (res.ok) return res.status
			})
			.catch(error => Promise.reject(error))
	}

	restoreTouchpointValues(touchpointId: string, field: number): Promise<any> {
		return fetch(`${this.baseUrl}/Restore/${touchpointId}/${field}`, {
			method: 'POST',
			credentials: 'include'
		})
			.then(res => res.json())
			.catch(error => Promise.reject(error))
	}
}
