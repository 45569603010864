


export class EmailBlastService {
    private readonly touchpointId: string;
    private readonly baseUrl: string;
    private readonly testUrl: string;
    private readonly headers: any;
    private readonly imgHeaders: any;

    constructor (touchpointId: string) {
        this.touchpointId = touchpointId;
        this.baseUrl = `${process.env.REACT_APP_API_URL}/EmailBlastTouchpoint`;
        this.testUrl = `https://localhost:5001/EmailBlastTouchpoint`;
        this.headers =       { 
            'Content-Type': 'application/json; ',
            // 'Access-Control-Allow-Credentials': 'true',        
        };
        this.imgHeaders = {
        };

        return this;
    }


    getTouchpoint(): Promise<any> {

        return fetch(`${this.baseUrl}/${this.touchpointId}`, { method: "GET", headers: this.headers, credentials: 'include' })
            .then(res => {
                if(res.ok) return res.json();
                else return Promise.reject(res);
            })
            .catch(error => Promise.reject(error))
    }

    updateTouchpoint(touchpoint: any): Promise<any> {

        return fetch(`${this.baseUrl}/${touchpoint.id}`, {
            method: 'PUT',
            headers: this.headers,
            credentials: 'include',
            body: JSON.stringify(touchpoint),
        })
            .then(res => {
                if(res.ok) return res.status;
                else return res.json();
            })
            .then(json => {
                if(json === 200) return 200;
                else return Promise.reject(json);
            })
            .catch(error => Promise.reject(error))
    }

    deleteTouchpoint(): Promise<any> {
        return fetch(`${this.baseUrl}/${this.touchpointId}`, { method: "DELETE", headers: this.headers, credentials: "include" })
            .then(res => {
                if(res.ok) return res.status;
                else return Promise.reject(res);
            })
            .catch(error => Promise.reject(error))
    }
    
    uploadTemplateFooterImage(image: any): Promise<any> {
        const body = new FormData();
        body.append('files', image);

        return fetch(`${this.baseUrl}/UploadFooterImage/${this.touchpointId}`, { method: 'POST', headers: this.imgHeaders, credentials: "include", body })
            .then(res =>{
                if (res.ok) return res.text();
                else return res.json()
            })
            .then(json => {
                if (typeof json === 'string') {
                    return json;
                }

                throw json;
            })
            .catch((error) => Promise.reject(error));
    }

    uploadTemplateHeaderImage(image: any): Promise<any> {
        const body = new FormData();
        body.append('files', image);

        return fetch(`${this.baseUrl}/UploadHeaderImage/${this.touchpointId}`, { method: 'POST', headers: this.imgHeaders, credentials: "include", body })
            .then(res =>{
                if (res.ok) return res.text();
                else return res.json()
            })
            .then(json => {
                if (typeof json === 'string') {
                    return json;
                }

                throw json;
            })
            .catch((error) => Promise.reject(error));
    }

    uploadTemplateBookItemImage(image: any, isbn: string): Promise<any> {
        const body = new FormData();
        body.append('files', image);

        return fetch(`${this.baseUrl}/UploadCoverImage/${this.touchpointId}?isbn=${isbn}`, { method: 'POST', headers: this.imgHeaders, credentials: "include", body })
            .then(res =>{
                if (res.ok) return res.text();
                else return res.json()
            })
            .then(json => {
                if (typeof json === 'string') {
                    return json;
                }

                throw json;
            })
            .catch((error) => Promise.reject(error));
    }

    getTemplates(): Promise<any> {
        return fetch(`${this.baseUrl}/Templates`, { method: "GET", headers: this.headers, credentials: "include" })
            .then(async (res) => {
                if(res.ok) return res.json();
                else {
                    const response = await res.json();
                    throw response;
                }
            })
            .catch(error => Promise.reject(error))
    }
  
}