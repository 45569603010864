import { IEmailBlastAvailableDate, IEmailBlastTemplate, IEmailBlastTouchpoint, TouchpointFormError } from '../../../models';

export type EmailBlastTouchpointState = {
  touchpoints: IEmailBlastTouchpoint[],
  dates: {
    loading: boolean,
    data: IEmailBlastAvailableDate[],
    error: any
  },
  templates: {
    loading: boolean,
    data: IEmailBlastTemplate[],
    error: any
  }
  step: string,
  loading: boolean,
  error: any,
  formErrors: Array<TouchpointFormError>,
}

export const SET_EBLAST_FORM_ERRORS = 'SET_EBLAST_FORM_ERRORS';

type setFormErrors = {
  type: typeof SET_EBLAST_FORM_ERRORS,
  payload: Array<TouchpointFormError>,
}

export const RESET_EBLAST_FORM_ERRORS = 'RESET_EBLAST_FORM_ERRORS';

type resetFormErrors = {
  type: typeof RESET_EBLAST_FORM_ERRORS,
}

export const DELETE_EBLAST_FORM_ERROR = 'DELETE_FB_FORM_ERROR';

type deleteFormError = {
  type: typeof DELETE_EBLAST_FORM_ERROR,
  payload: string, //field pointer
}

export const SET_EBLAST_TOUCHPOINTS = 'SET_EBLAST_TOUCHPOINTS';
export const SET_EBLAST_TOUCHPOINT = 'SET_EBLAST_TOUCHPOINT';
export const SET_EBLAST_TOUCHPOINT_STEP = 'ET_EBLAST_TOUCHPOINT_STEP';

type setTouchpoints = {
  type: typeof SET_EBLAST_TOUCHPOINTS,
  payload: IEmailBlastTouchpoint[]
}

type setTouchpoint = {
  type: typeof SET_EBLAST_TOUCHPOINT,
  payload: IEmailBlastTouchpoint,
}

type setStep = {
  type: typeof SET_EBLAST_TOUCHPOINT_STEP,
  payload: string
}

export const GET_EBLAST_TOUCHPOINT_REQUEST = 'GET_EBLAST_TOUCHPOINT_REQUEST';
export const GET_EBLAST_TOUCHPOINT_SUCCESS = 'GET_EBLAST_TOUCHPOINT_SUCCESS';
export const GET_EBLAST_TOUCHPOINT_FAILED = 'GET_EBLAST_TOUCHPOINT_FAILED';

type getTochpointRequest = {
  type: typeof GET_EBLAST_TOUCHPOINT_REQUEST
}
type getTochpointSuccess = {
  type: typeof GET_EBLAST_TOUCHPOINT_SUCCESS,
  payload: IEmailBlastTouchpoint,
}
type getTochpointFailed = {
  type: typeof GET_EBLAST_TOUCHPOINT_FAILED,
  payload: string
}

export const UPDATE_EBLAST_TOUCHPOINT_REQUEST = 'UPDATE_EBLAST_TOUCHPOINT_REQUEST';
export const UPDATE_EBLAST_TOUCHPOINT_SUCCESS = 'UPDATE_EBLAST_TOUCHPOINT_SUCCESS';
export const UPDATE_EBLAST_TOUCHPOINT_FAILED = 'UPDATE_EBLAST_TOUCHPOINT_FAILED';

type updateTochpointRequest = {
  type: typeof UPDATE_EBLAST_TOUCHPOINT_REQUEST,
}
type updateTochpointSuccess = {
  type: typeof UPDATE_EBLAST_TOUCHPOINT_SUCCESS,
}
type updateTochpointFailed = {
  type: typeof UPDATE_EBLAST_TOUCHPOINT_FAILED,
  payload: string
}

export const DELETE_EBLAST_TOUCHPOINT_REQUEST = 'DELETE_EBLAST_TOUCHPOINT_REQUEST';
export const DELETE_EBLAST_TOUCHPOINT_SUCCESS = 'DELETE_EBLAST_TOUCHPOINT_SUCCESS';
export const DELETE_EBLAST_TOUCHPOINT_FAILED = 'DELETE_EBLAST_TOUCHPOINT_FAILED';

type deleteTochpointRequest = {
  type: typeof DELETE_EBLAST_TOUCHPOINT_REQUEST
}
type deleteTochpointSuccess = {
  type: typeof DELETE_EBLAST_TOUCHPOINT_SUCCESS,
  payload: string,
}
type deleteTochpointFailed = {
  type: typeof DELETE_EBLAST_TOUCHPOINT_FAILED,
  payload: string,
}

export const GET_EBLAST_AVAILABLE_DATES_REQUEST = 'GET_EBLAST_AVAILABLE_DATES_REQUEST';
export const GET_EBLAST_AVAILABLE_DATES_SUCCESS = 'GET_EBLAST_AVAILABLE_DATES_SUCCESS';
export const GET_EBLAST_AVAILABLE_DATES_FAILED = 'GET_EBLAST_AVAILABLE_DATES_FAILED';

type getAvailableDatesRequest = {
  type: typeof GET_EBLAST_AVAILABLE_DATES_REQUEST
}
type getAvailableDatesSuccess = {
  type: typeof GET_EBLAST_AVAILABLE_DATES_SUCCESS,
  payload: Array<any>
}
type getAvailableDatesFailed = {
  type: typeof GET_EBLAST_AVAILABLE_DATES_FAILED,
  payload: any,
}

export const GET_EBLAST_TEMPLATES_REQUEST = "GET_EBLAST_TEMPLATES_REQUEST";
export const GET_EBLAST_TEMPLATES_SUCCESS = "GET_EBLAST_TEMPLATES_SUCCESS";
export const GET_EBLAST_TEMPLATES_FAILED = "GET_EBLAST_TEMPLATES_FAILED";

type getTemplatesRequest = {
  type: typeof GET_EBLAST_TEMPLATES_REQUEST,
}

type getTemplatesSuccess = {
  type: typeof GET_EBLAST_TEMPLATES_SUCCESS,
  payload: IEmailBlastTemplate[]
}

type getTemplatesFailed = {
  type: typeof GET_EBLAST_TEMPLATES_FAILED,
  payload: any
}

export const INITIALIZE_TOUCHPOINT_TEMPLATE_REQUEST = "INITIALIZE_TOUCHPOINT_TEMPLATE_REQUEST";
export const INITIALIZE_TOUCHPOINT_TEMPLATE_SUCCESS = "INITIALIZE_TOUCHPOINT_TEMPLATE_SUCCESS";
export const INITIALIZE_TOUCHPOINT_TEMPLATE_FAILED = "INITIALIZE_TOUCHPOINT_TEMPLATE_FAILED";

type initializeTouchpointTemplateRequest = {
  type: typeof INITIALIZE_TOUCHPOINT_TEMPLATE_REQUEST,
}

type initializeTouchpointTemplateSuccess = {
  type: typeof INITIALIZE_TOUCHPOINT_TEMPLATE_SUCCESS,
}

type initializeTouchpointTemplateFailed = {
  type: typeof INITIALIZE_TOUCHPOINT_TEMPLATE_FAILED,
  payload: any
}


export type DispatchTypes =
  setFormErrors | resetFormErrors | deleteFormError |
  setTouchpoints | setTouchpoint | setStep |
  getTochpointRequest | getTochpointSuccess | getTochpointFailed |
  updateTochpointRequest | updateTochpointSuccess | updateTochpointFailed |
  deleteTochpointRequest | deleteTochpointSuccess | deleteTochpointFailed |
  getAvailableDatesRequest | getAvailableDatesSuccess | getAvailableDatesFailed |
  getTemplatesRequest | getTemplatesSuccess | getTemplatesFailed |
  initializeTouchpointTemplateRequest | initializeTouchpointTemplateSuccess | initializeTouchpointTemplateFailed;