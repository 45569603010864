export class GoogleService {
	private readonly touchpointId: string;
	private readonly baseUrl: string;

	constructor(touchpointId: string) {
		this.touchpointId = touchpointId;
		this.baseUrl = `${process.env.REACT_APP_API_URL}/GoogleTouchpoint`;

		return this;
	}

	getTouchpoint(): Promise<any> {
		console.log("**** => getting touchpoint", this.touchpointId)
		return fetch(`${this.baseUrl}/${this.touchpointId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json; ',
				// 'Access-Control-Allow-Credentials': 'true',      
			},
			credentials: "include"
		})
			.then(res => {
				if (res.ok) return res.json();
				else return Promise.reject(res);
			})
			.catch(error => Promise.reject(error))
	}

	updateTouchpoint(touchpoint: any): Promise<any> {
		return fetch(`${this.baseUrl}/${touchpoint.id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json; ',
				// 'Access-Control-Allow-Credentials': 'true',      
			},
			credentials: "include",
			body: JSON.stringify(touchpoint),
		})
			.then(res => {
				if (res.ok) return res.status;
				else return res.json();
			})
			.then(json => {
				if (json === 200) return 200;
				else return Promise.reject(json);
			})
			.catch(error => Promise.reject(error))
	}

	deleteTouchpoint(): Promise<any> {
		return fetch(`${this.baseUrl}/${this.touchpointId}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json; ',
				// 'Access-Control-Allow-Credentials': 'true',      
			},
			credentials: "include"
		})
			.then(res => {
				if (res.ok) return res.status;
				else return Promise.reject(res);
			})
			.catch(error => Promise.reject(error))
	}

	validateTouchpoint(): Promise<any> {
		return fetch(`${this.baseUrl}/Validate/${this.touchpointId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json; ',
				// 'Access-Control-Allow-Credentials': 'true',      
			},
			credentials: "include"
		})
			.then(res => {
				if (res.ok) {
					return res.status;
				}

				return res.json();
			})
			.catch(error => error.message)
	}

	getAudienceOptions(): Promise<any> {
		return fetch(`${this.baseUrl}/GetAudienceOptions/${this.touchpointId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json; ',
				// 'Access-Control-Allow-Credentials': 'true',      
			},
			credentials: "include"
		})
			.then(res => {
				if (res.ok) return res.json()
				else return Promise.reject(res)
			})
			.catch(error => Promise.reject(error))
	}

	getBisacKeywords(): Promise<any> {
		return fetch(`${this.baseUrl}/getBisacKeywords/${this.touchpointId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json; ',
				// 'Access-Control-Allow-Credentials': 'true',      
			},
			credentials: "include"
		})
			.then(res => res.json())
			.then(json =>
				json.message
					? Promise.reject(json.message)
					: json
			)
			.catch(error => Promise.reject(error))
	}

	getCustomKeywords(query: any): Promise<any> {
		return fetch(`${this.baseUrl}/getByFinalWord/${this.touchpointId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; ',
			},
			credentials: "include",
			body: JSON.stringify(query)
		})
			.then(res => res.json())
			.then(json =>
				json.message
					? Promise.reject(json.message)
					: json
			)
			.catch(error => Promise.reject(error))
	}

	updateRecommendedAudiences(touchpointId: string, audiendeIds: string[]) {
		return fetch(`${this.baseUrl}/UpdateRecommendedAudiences/${touchpointId}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json; ',
			},
			credentials: 'include',
			body: JSON.stringify(audiendeIds),
		})
	}

	uploadSquareImages(files: any[]): Promise<any> {
		const filesToSend = new FormData();

		files.map((file, i) => {
			file.fileName = `${this.touchpointId}-${i}t0`;
			return filesToSend.append('files', file)
		});

		return fetch(`${this.baseUrl}/UploadSquareImage/${this.touchpointId}`, {
			method: 'POST',
			credentials: "include",
			body: filesToSend,
		})
			.then(res => {
				if (res.ok) return res.json();
				else if (res.status === 400) return res.json();
			})
			.catch(error => Promise.reject(error))
	}

	uploadLandscapeImages(files: any[]): Promise<any> {
		const filesToSend = new FormData();

		files.map((file, i) => {
			file.filename = `${this.touchpointId}-${i}t1`;
			return filesToSend.append('files', file)
		});


		return fetch(`${this.baseUrl}/UploadLandscapeImage/${this.touchpointId}`, {
			method: 'POST',
			credentials: "include",
			body: filesToSend,
		})
			.then(res => {
				if (res.ok) return res.json();
				else if (res.status === 400) return res.json();
			})
			.catch(error => Promise.reject(error))
	}

	uploadSquareLogoImages(files: any[]): Promise<any> {
		const filesToSend = new FormData();

		files.map((file, i) => {
			file.fileName = `${this.touchpointId}-${i}t2`;
			return filesToSend.append('files', file)
		});


		return fetch(`${this.baseUrl}/UploadSquareLogoImage/${this.touchpointId}`, {
			method: 'POST',
			credentials: "include",
			body: filesToSend,
		})
			.then(res => {
				if (res.ok) return res.json();
				else if (res.status === 400) return res.json();
			})
			.catch(error => Promise.reject(error))
	}

	uploadLandscapeLogoImages(files: any[]): Promise<any> {
		const filesToSend = new FormData();

		files.map((file, i) => {
			file.fileName = `${this.touchpointId}-${i}t3`;
			return filesToSend.append('files', file)
		});


		return fetch(`${this.baseUrl}/UploadLandscapeLogoImage/${this.touchpointId}`, {
			method: 'POST',
			credentials: "include",
			body: filesToSend,
		})
			.then(res => {
				if (res.ok) return res.json();
				else if (res.status === 400) return res.json();
			})
			.catch(error => Promise.reject(error))
	}

	deleteTouchpointImage(imageId: string) {
		return fetch(`${this.baseUrl}/DeleteImage/${this.touchpointId}/${imageId}`, {
			method: 'DELETE',
			credentials:  'include'
		})
	}

	restoreTouchpointValues(field: number): Promise<any> {
		return fetch(`${this.baseUrl}/Restore/${this.touchpointId}/${field}`, {
			method: 'POST',
			credentials: 'include'
		})
			.then(res => res.json())
			.catch(error => Promise.reject(error))
	}
}
